import { useState } from 'react'
import { rem } from 'polished'
import he from 'he'
import moment from 'moment-timezone'
import Icon from 'components/atoms/Icon'

export const URL_REGEXP = new RegExp(
  /^(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:com|org|net|edu|gov|mil|int|info|biz|mobi|name|aero|asia|jobs|museum|travel|xxx|pro|tel|coop|cat|post|arpa|club|xyz|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bl|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|eh|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw))(?::\d{2,5})?(?:[/?#]\S*)?$/i
)

export const pxToRem = (pxVal) => rem(pxVal, 18)

export const initializeArrayWithRange = (end, start = 0) =>
  Array.from({ length: end + 1 - start }).map((v, i) => i + start)

export const initializeStringArrayWithRange = ({ start = 0, end }) => {
  const data = []
  for (let i = start; i <= end; i += 1) {
    data.push(i < 10 ? `0${i}` : `${i}`)
  }
  return data
}

export const countBy = (arr, fn) =>
  arr
    // eslint-disable-next-line no-nested-ternary
    .map(fn ? (typeof fn === 'function' ? fn : (val) => val[fn]) : (val) => val)
    .reduce((acc, val) => {
      acc[val] = (acc[val] || 0) + 1
      return acc
    }, {})

export const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size))

export const sum = (arr) => arr.reduce((a, b) => a + b, 0)
export const withSign = (number) => {
  if (number === null || number === undefined) {
    return ''
  } else {
    return Math.sign(number) === 1 ? `+${number.toLocaleString()}` : number.toLocaleString()
  }
}
export const shortFormatDateRange = (dateFrom, dateTo) =>
  `${moment(dateFrom).format('MMM DD')}-${moment(dateTo).format('MMM DD')}`

export const mapReactSelectValueToApiArray = ({ value, label }) => ({ id: value, name: label })
export const mapValueToReactSelect = ({ id, name, description }) => ({ value: id, label: description || name })
export const mapArrayToReactSelect = (arr) => arr.map(mapValueToReactSelect)
export const mapReactSelectToApiArray = (arr) => arr.map(mapReactSelectValueToApiArray)
export const transformToReactSelectData = (data) => {
  if (data) {
    return { value: data.id, label: data.description || data.name }
  } else {
    return ''
  }
}
export const getStateOptionsFromCountries = ({ states, country }) => {
  if (!country) {
    return []
  }
  return mapArrayToReactSelect(states.filter((item) => item.country === country.value))
}

export const formatDateToHumanReadable = (date) =>
  new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

export const formatToNumberWithCommas = (number) => {
  if (number !== null && number !== undefined) {
    try {
      if (!isNaN(number)) {
        return Number(number).toLocaleString()
      } else {
        return number
      }
    } catch (error) {
      return number
    }
  } else {
    return 0
  }
}

export const stripHtml = (string) => string.replace(/<[^>]*>?/gm, '')
export const parseTextFromServer = (string) => {
  if (string) {
    try {
      return stripHtml(he.decode(string)).trim()
    } catch (error) {
      console.log('unable to strip html', error)
    }

    return string.trim()
  } else {
    return string
  }
}

export const isInViewport = (element) => {
  const bounding = element.getBoundingClientRect()
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const truncate = (string, maxChar) => {
  if (string && string.length > maxChar) return `${string.substring(0, maxChar)}${maxChar ? '...' : ''}`
  else return string
}

const gtfr = (s) => {
  let total = 0
  for (let i = 0; i < s.length; i++) {
    total += s.charCodeAt(i) * i - 22
  }
  return Math.pow(total, 3).toLocaleString('fullwide', { useGrouping: false })
}

export const linkify = (string, id) => {
  // const start = new Date().getTime()

  try {
    if (!string) {
      return ''
    }
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
    return string.replace(urlRegex, (url) => {
      return `<a href="/api/link?${id ? `id=${id}&` : ``}url=${encodeURIComponent(url)}&token=${gtfr(
        url
      )}" target="_blank" rel="noopener noreferrer nofollow">${url}</a>`
    })
  } finally {
    // console.log(`completed linkify in ${new Date().getTime() - start}`)
  }
}

export const HASHTAG_REGEXP = /<a\b[^>]*>|\B(#[^ !@#$%^&*(),.?":{}_\-|<>]*)/gi

export const hashtagTransformer = ({ body, agencyId, color }) => {
  const start = new Date().getTime()

  try {
    return linkify(body, agencyId).replace(HASHTAG_REGEXP, (m, p) =>
      p >= 0 ? `<span style="color: ${color}; font-weight: 500;">${m}</span>` : m
    )
  } finally {
    // console.log(`completed hashtagTransformer in ${new Date().getTime() - start}`)
  }
}

// this is not used, hashtag transforming has been transferred to linkTransformer function
export const hashtagTransformerWithoutLinking = ({ body, color, fontWeight = 500 }) => {
  if (body) {
    return body.replace(HASHTAG_REGEXP, (m, p) =>
      p >= 0 ? `<span style="color: ${color}; font-weight: ${fontWeight};">${m}</span>` : m
    )
  }
  return ''
}

export const trackableLink = (string, id, type) => {
  if (!string) {
    return ''
  }

  return `/api/link?${id ? `id=${id}&` : `&`}${type ? `type=${type}&` : `&`}url=${encodeURIComponent(
    string
  )}&token=${gtfr(string)}`
}

export const deslugify = (string) => {
  if (string) {
    return string
      .split('-')
      .map((word) => `${word[0].toUpperCase()}${word.substr(1)}`)
      .join(' ')
  }
  return string
}

export const isEmptyObject = (object) => Object.entries(object).length === 0 && object.constructor === Object

export const useAsyncState = (initialValue) => {
  const [value, setValue] = useState(initialValue)
  const setter = (x) =>
    new Promise((resolve) => {
      setValue(x)
      resolve(x)
    })
  return [value, setter]
}

export const getIconByMarketingObjectiveId = (id) => {
  if (id === 1) {
    return Icon.TrendingUp
  }
  if (id === 2) {
    return Icon.GroupEqual
  }
  if (id === 3) {
    return Icon.MouseCursorOutlined
  }
  if (id === 4) {
    return Icon.SmsChatOutlined
  }
  return Icon.FavoriteComment
}

export const convertToBase64String = (image) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = (e) => {
      resolve(e.target.result)
    }
    reader.readAsDataURL(image)
  })
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const downloadFile = ({ file, file_name }) => {
  const bytes = new Uint8Array(file.data)
  const blob = new Blob([bytes])
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', file_name)
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

export const getTimeZones = () => {
  const offsetTmz = []
  const countries = moment.tz.countries()
  countries.forEach((country) => {
    const timeZones = moment.tz.zonesForCountry(country)
    timeZones.forEach((zone) => {
      const time = moment.tz(zone).format('Z')
      offsetTmz.push({
        value: zone,
        label: `(GMT ${time}) ${zone.replace(/_/g, ' ')}`,
        time: Number(time.split(':')[0]),
      })
    })
    offsetTmz.sort((a, b) => (a.time < b.time ? -1 : a.time > b.time ? 1 : 0))
  })
  return offsetTmz
}

export const getTimeZone = ({ defaultTz = 'Etc/UTC' } = {}) => {
  const timezone = process.browser ? getTimeZones().find(({ value }) => value === moment.tz.guess()) || null : null

  if (timezone) {
    return timezone.value
  } else {
    return defaultTz
  }
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const linkTransformer = ({
  body,
  color,
  fontWeight = 500,
  isClickable = true,
  transformHashstag = false,
  hashtagColor,
  hashtagFontWeight = 500,
  linkShorteningFunction,
  click_url,
  post_id,
}) => {
  if (body) {
    return body
      .split('\n')
      .map((line) => {
        return line
          .split(' ')
          .map((word) => {
            const res = word.match(URL_REGEXP)
            if (res !== null) {
              const hasProtocol = word.startsWith('http')

              return `<a href="${hasProtocol ? word : `https://${word}`}"  
                target="_blank" rel="noreferrer noopener nofollow" 
                style="color: ${color}; font-weight: ${fontWeight}; text-decoration: none; display: inline; white-space: normal; pointer-events: ${
                isClickable ? 'initial' : 'none'
              }" 
                ${click_url ? `onclick=vista_page_track("${click_url}","clicks","${post_id}")` : ''}>
                ${linkShorteningFunction ? linkShorteningFunction({ word }) : `${word}` || ''}</a>`
            } else if (word && word.length !== 0) {
              if (transformHashstag) {
                return word.replace(HASHTAG_REGEXP, (m, p) =>
                  p >= 0 ? `<span style="color: ${hashtagColor}; font-weight: ${hashtagFontWeight};">${m}</span>` : m
                )
              }
            }
            return word
          })
          .join(' ')
      })
      .join('\n')
  }
  return ''
}

export const clearStringFromSymbols = (str) => {
  let lower = str.trim()
  lower = lower.replace(/[;:!$*?@=<>#%{}"()”|\\^~\[\]`]/g, '')
  lower = lower.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ''
  )
  lower = lower.replace(/[.,_&-/+]/g, ' ')
  return lower
}

export const countBase64ImageSize = ({ image }) => {
  const justImage = image.replace(/^data:image\/\w+;base64,/, '')
  // eslint-disable-next-line no-nested-ternary
  return (justImage.length * 3) / 4 - (justImage.endsWith('==') ? 2 : justImage.endsWith('=') ? 1 : 0)
}

// eslint-disable-next-line no-useless-escape
export const isValidInstagramHashtag = (str) => str.match(/^#[^~`!@#$%^&*\(\)\-\+={}\[\]:;"'<>\?,\./|\\\s]+$/) !== null

export const isValidInstagramUsername = (str) => {
  return str.match(/(?:@)([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/g) !== null
}

const special = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
]
const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet']

export const stringifyNumber = (n) => {
  if (n < 20) return special[n]
  if (n % 10 === 0) return `${deca[Math.floor(n / 10) - 2]}ieth`
  return `${deca[Math.floor(n / 10) - 2]}y-${special[n % 10]}`
}

const delay = ({ value, delay = 1000 } = {}) => new Promise((resolve) => setTimeout(() => resolve(value), delay))

export const waitForUrl = async ({ url, interval = 2000, attempts = 3 }) => {
  // const debug = console.log

  let attempt = 0
  while (attempt++ < attempts) {
    // debug(`attempting ${attempt} of ${attempts} getting media meta information from ${url}`)
    // let's wait for PDF
    // const url = `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/${file}?v=${Date.now()}`

    let res

    try {
      res = await fetch(url)
    } catch (error) {
      console.error(`An error occurred while waiting for url in helpers`, error)
    }

    if (res && res.status === 200) {
      return await res.text()
    } else {
      // debug(
      //   `waiting for cloudfront url to become available ${url}, status ${
      //     res ? res.status : 404
      //   }, attempt ${attempt} of ${attempts}`
      // )

      await delay({ delay: interval })
    }
  }

  return undefined
}

export const reverseHtml = (str) => {
  const ph = String.fromCharCode(206)
  let result = str
    .split('')
    .reverse()
    .join('')
  while (result.indexOf('<') > -1) {
    result = result.replace('<', ph)
  }
  while (result.indexOf('>') > -1) {
    result = result.replace('>', '<')
  }
  while (result.indexOf(ph) > -1) {
    result = result.replace(ph, '>')
  }
  return result
}

export const truncateHTML = ({ text, start = 0, end }) => {
  if (!text) {
    return undefined
  }

  let truncated = text.substring(start, end)

  if (text.length > end) {
    truncated += '...'
  }

  // Remove line breaks and surrounding whitespace
  truncated = truncated.replace(/(\r\n|\n|\r)/gm, '').trim()
  // If the text ends with an incomplete start tag, trim it off
  truncated = truncated.replace(/<(\w*)(?:(?:\s\w+(?:={0,1}(["']{0,1})\w*\2{0,1})))*$/g, '')
  // If the text ends with a truncated end tag, fix it.
  const truncatedEndTagExpr = /<\/((?:\w*))$/g
  const truncatedEndTagMatch = truncatedEndTagExpr.exec(truncated)
  if (truncatedEndTagMatch != null) {
    const truncatedEndTag = truncatedEndTagMatch[1]
    // Check to see if there's an identifiable tag in the end tag
    if (truncatedEndTag.length > 0) {
      // If so, find the start tag, and close it
      const startTagExpr = new RegExp(`<(${truncatedEndTag}\\w?)(?:(?:\\s\\w+(?:=(["'])\\w*\\2)))*>`)
      let testString = truncated
      let startTagMatch = startTagExpr.exec(testString)

      let startTag = null
      while (startTagMatch != null) {
        // eslint-disable-next-line prefer-destructuring
        startTag = startTagMatch[1]
        testString = testString.replace(startTagExpr, '')
        startTagMatch = startTagExpr.exec(testString)
      }
      if (startTag != null) {
        truncated = truncated.replace(truncatedEndTagExpr, `</${startTag}>`)
      }
    } else {
      // Otherwise, cull off the broken end tag
      truncated = truncated.replace(truncatedEndTagExpr, '')
    }
  }
  // Now the tricky part. Reverse the text, and look for opening tags. For each opening tag,
  //  check to see that he closing tag before it is for that tag. If not, append a closing tag.
  let testString = reverseHtml(truncated)
  const reverseTagOpenExpr = /<(?:(["'])\w*\1=\w+ )*(\w*)>/
  let tagMatch = reverseTagOpenExpr.exec(testString)
  while (tagMatch != null) {
    const tag = tagMatch[0]
    const tagName = tagMatch[2]
    const startPos = tagMatch.index
    const endPos = startPos + tag.length
    const fragment = testString.substring(0, endPos)
    // Test to see if an end tag is found in the fragment. If not, append one to the end
    //  of the truncated HTML, thus closing the last unclosed tag
    if (!new RegExp(`<${tagName}/>`).test(fragment)) {
      truncated += `</${reverseHtml(tagName)}>`
    }
    // Get rid of the already tested fragment
    testString = testString.replace(fragment, '')
    // Get another tag to test
    tagMatch = reverseTagOpenExpr.exec(testString)
  }
  return truncated
}

export const originCleanUp = ({ origin }) => {
  let updatedOrigin = origin

  if (updatedOrigin.includes('#_=_') || updatedOrigin.includes('#_')) {
    if (updatedOrigin.includes('#_=_')) {
      updatedOrigin = updatedOrigin.replace('#_=_', '')
    } else if (updatedOrigin.includes('#_')) {
      updatedOrigin = updatedOrigin.replace('#_', '')
    }
  }
  return encodeURIComponent(updatedOrigin)
}

export const getFileIconByExtension = ({ extension }) => {
  let src = '/assets/file.svg'

  if (extension) {
    if (extension.includes('doc')) {
      src = `/assets/file_doc.svg`
    } else if (extension.includes('ppt')) {
      src = `/assets/file_ppt.svg`
    } else if (extension === 'pdf') {
      src = `/assets/file_pdf.svg`
    }
  }

  return src
}

export const isValidUrl = ({ url }) => URL_REGEXP.test(url)

export const convertTextToHTML = ({ text }) => {
  return text
    .split('\n')
    .filter((v) => v.trim())
    .map((content) => `<p style="margin: 0">${content.trim()}</p><br/>`)
    .join('\n')
}

export const countAlertTimeout = ({ text = '' }) => {
  const textLength = text ? text.length : 0

  const defaultTimeout = 5000

  if (!textLength) {
    return defaultTimeout
  }

  const newTimeout = (textLength / (1000 / 60)) * 1000 // we think that people read about 1000 symbols per minute

  return newTimeout > defaultTimeout ? newTimeout : defaultTimeout
}
