import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import NextNprogress from 'nextjs-progressbar'
import Alert from 'react-s-alert'
import { theme, colors, GlobalStyles } from 'theme'
import AlertTemplate from 'shared/AlertTemplate'
import '@material-ui/styles'
import '@reach/dialog/styles.css'
import 'sanitize.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-quill/dist/quill.snow.css'
import 'fonts.css'
import 'slick-carousel/slick/slick.css'
import 'tui-image-editor/dist/tui-image-editor.css'
import 'tui-color-picker/dist/tui-color-picker.css'
import 'intro.js/introjs.css'
import withoutPageDataProps from 'utils/withoutPageDataProps'
import ErrorBoundary from './ErrorBoundary'

const App = ({ children }) => {
  useEffect(() => {}, [])
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <NextNprogress
            color={colors.primary}
            startPosition={0.3}
            stopDelayMs={200}
            options={{ easing: 'ease', speed: 500, showSpinner: false }}
            height={3}
          />
          <GlobalStyles />
          {children}
          <Alert stack={{ limit: 3 }} contentTemplate={AlertTemplate} position="top-right" />
        </React.Fragment>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

App.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withoutPageDataProps(App)
