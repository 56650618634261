import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Router from 'next/router'
import { COLOR_CONSTANTS } from 'theme'
import withMeta from 'utils/withMeta'
import FullScreen from 'shared/layouts/FullScreen'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'

const BgImg = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  z-index: 0;
`

const StyledButtonWithLoading = styled(ButtonWithLoading)`
  height: 50px !important;
  width: 234px;
`

const StyledErrorMessageText = styled(Text)`
  line-height: 32px;
`

const UNEXPECTED_PATHNAME = '/unexpected'

const getErrorMessageFromPathname = (pathname) => {
  if (pathname === '/denied') {
    return 'Access is denied.'
  }
  if (pathname === '/missing') {
    return "We couldn't find the page you were looking for. <br/> Maybe the link to the page got outdated or moved somewhere else."
  }
  if (pathname === UNEXPECTED_PATHNAME) {
    return 'Seems like there was a problem with our servers. Please contact support!'
  }
  return "We couldn't find the page you were looking for. <br/> Maybe the link to the page got outdated or moved somewhere else."
}

const getIllustrationFromPathname = (pathname) => {
  if (pathname === '/denied') {
    return '/assets/404.svg'
  }
  if (pathname === '/missing') {
    return '/assets/404.svg'
  }
  if (pathname === UNEXPECTED_PATHNAME) {
    return '/assets/500.svg'
  }
  return '/assets/404.svg'
}

const Error = ({ pathname }) => {
  const errorMessage = getErrorMessageFromPathname(pathname)
  const illustration = getIllustrationFromPathname(pathname)
  return (
    <FullScreen>
      <Flex position="relative" width="100%" height="100%" bg={COLOR_CONSTANTS.WHITE}>
        <BgImg src="/assets/vistasocial/bg_page.svg" alt="" />
        <Flex mx="auto" flexDirection="column" alignItems="center">
          <Image
            mt="m"
            src={illustration}
            maxHeight="400px"
            maxWidth={{ mobile: '300px', mobileLarge: '100%', tablet: '100%' }}
          />
          <Text my="m" color={COLOR_CONSTANTS.SKY} fontSize="60px" textAlign="center">
            Whoops !
          </Text>
          <StyledErrorMessageText
            mt="m"
            pt="s"
            fontSize="m"
            color={COLOR_CONSTANTS.DENIM}
            dangerouslySetInnerHTML={{ __html: errorMessage }}
            textAlign="center"
          />
          <Flex my="l" justifyContent="center">
            <StyledButtonWithLoading isLarge onClick={() => Router.push('/')} mt="m" width="100%">
              <Text>Back to home page</Text>
            </StyledButtonWithLoading>
          </Flex>
        </Flex>
      </Flex>
    </FullScreen>
  )
}

Error.getInitialProps = ({ pathname }) => {
  return { pathname }
}

Error.propTypes = {
  pathname: PropTypes.string.isRequired,
}

export default withMeta({ title: 'Error' })(Error)
