import React from 'react'
import PropTypes from 'prop-types'
import App from 'App'

const FullScreen = ({ children }) => <App>{children}</App>

FullScreen.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FullScreen
