import styled from 'styled-components'
import { width, height, space as styledSpace, maxWidth as styledMaxWidth } from 'styled-system'
import { transparentize, desaturate } from 'polished'
import { space, fontWeights, fontSizes, radius, colors, COLOR_CONSTANTS } from 'theme'
import { pxToRem } from 'helpers'

const ButtonBase = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${radius.l};
  padding-left: ${space.xl};
  padding-right: ${space.xl};
  min-width: ${space.xxl};
  font-weight: ${fontWeights.normal};
  font-size: ${fontSizes.m};
  height: ${pxToRem(40)};
  user-select: none;
  color: ${COLOR_CONSTANTS.WHITE};
  transition: background-color 0.12s ease-in-out, border-color 0.12s ease-in-out, color 0.15s ease-in-out;
  ${({ isSmall }) =>
    isSmall &&
    `
      height: ${pxToRem(30)};
      font-size: ${fontSizes.s};
    `};
  ${({ isDisabled }) =>
    isDisabled &&
    `
      pointer-events: none;
  `};
  ${width};
  ${height};
  ${styledSpace};
  ${styledMaxWidth};
`

const Basic = styled(ButtonBase)``

const Primary = styled(ButtonBase)`
  padding-left: ${space.s};
  padding-right: ${space.s};
  min-width: ${space.xl};
  display: flex;
  text-decoration: none;
  border: 1px solid ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  background: linear-gradient(90deg, #0b60da 0%, #2482fd 100%);
  color: ${colors.white};
  ${({ isDisabled, primaryColor }) =>
    isDisabled &&
    `
    background-color: ${transparentize(0.5, primaryColor || colors.primary)};
  `}
`

const Error = styled(ButtonBase)`
  padding-left: ${space.s};
  padding-right: ${space.s};
  min-width: ${space.xl};
  background-color: ${COLOR_CONSTANTS.FLUORESCENT_RED};
  color: ${COLOR_CONSTANTS.WHITE};
  border: 1px solid ${COLOR_CONSTANTS.FLUORESCENT_RED};
  &:hover {
    background-color: ${COLOR_CONSTANTS.FLUORESCENT_RED};
  }
`

const Gray = styled(ButtonBase)`
  padding-left: ${space.s};
  padding-right: ${space.s};
  min-width: ${space.xl};
  background-color: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  color: ${COLOR_CONSTANTS.SALUTE};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  &:hover {
    background-color: ${COLOR_CONSTANTS.WHITE};
  }
`

const Secondary = styled(ButtonBase)`
  background-color: ${transparentize(0.9, colors.primary)};
  color: ${colors.primary};
`
const Bordered = styled(ButtonBase)`
  padding-left: ${space.m};
  padding-right: ${space.m};
  background-color: ${colors.white};
  color: ${colors.primary};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  box-shadow: 0px 4px 10px rgba(39, 40, 49, 0.03);
  ${styledSpace};
`

const LinkedIn = styled(ButtonBase)`
  background-color: ${COLOR_CONSTANTS.FLAT_BLUE};
  ${width};
`

const Reversed = styled(ButtonBase)`
  display: flex;
  text-decoration: none;
  border: 1px solid ${COLOR_CONSTANTS.SALT};
  background-color: ${({ isDisabled }) => (isDisabled ? desaturate(0.3, colors.white) : colors.white)};
  color: ${colors.primary};
  border-radius: ${radius.l};
  ${({ isDisabled }) => isDisabled && `cursor: initial;`}
  &:hover {
    background: ${colors.primary};
    border: 1px solid ${colors.white};
    color: ${colors.white};
  }
`

// background-color: ${({ isDisabled }) => (isDisabled ? transparentize(0.3, colors.white) : colors.white)};
// color: ${colors.primary};
// ${({ isDisabled }) => isDisabled && `cursor: initial;`}
// ${({ isInverted }) =>
//   isInverted &&
//   `
//   background-color: ${colors.white};
//   color: ${colors.primary};
// `}

const Gradient = styled(Primary)`
  &:hover {
    background: #ffffff;
    border: 1px solid ${colors.primary};
    color: ${colors.primary};
    svg {
      fill: ${colors.primary};
    }
  }
`

export default {
  Basic,
  Primary,
  Gray,
  Secondary,
  Bordered,
  LinkedIn,
  Reversed,
  Gradient,
  Error,
}
